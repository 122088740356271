@tailwind base;
@tailwind components;
@tailwind utilities;

.react-tabs__tab--selected.title {
  color: red !important;
}

/* select chain */
.basic-select-container {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
}
.basic-select__control {
  background-color: transparent !important;
  outline: none !important;
  border: none !important;
  width: 302px !important;
}
.basic-select__menu {
  background-color: transparent !important;
  outline: none !important;
  border: none !important;
}

.basic-select__option {
  padding: 24px 32px !important;
  width: 302px !important;
  background: rgba(255, 255, 255, 0.06) !important;
  border-radius: 0px 0px 32px 32px !important;
}
