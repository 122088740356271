@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #0f0114;
  background-image: url("../src/assets/Icons/Group.svg");
  background-repeat: no-repeat;
  width: auto;
  height: auto;
  background-position-x: right;
}
